<template>
  <div class="flex h-[calc(100vh-64px)]">
    <ConversationList />
    
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Empty state -->
      <div v-if="!conversationId" class="flex-1 flex items-center justify-center text-gray-500">
        Select a conversation or start a new one
      </div>

      <!-- Active conversation -->
      <div v-else class="flex-1 flex flex-col overflow-hidden">
        <!-- Header - fixed height -->
        <div class="shrink-0 p-4 border-b bg-white">
          <div class="flex justify-between items-center">
            <h2 class="text-lg font-semibold">
              {{ activeConversation?.title || 'Chat' }}
            </h2>
            <!-- Add participants list -->
            <div class="flex items-center gap-4">
              
              <button 
                @click="showAddParticipantModal = true"
                class="btn btn-secondary btn-sm"
              >
                Add Participant
              </button>
            </div>
          </div>
        </div>

        <!-- Messages - flexible height -->
        <div 
          ref="messagesContainer"
          class="flex-1 overflow-y-auto p-4 space-y-4"
          @scroll="handleScroll"
        >
          <div v-if="loading" class="text-center py-4">
            <span class="text-gray-500">Loading messages...</span>
          </div>

          <template v-else>
            <div v-for="message in messages" :key="message.id">
              <MessageItem :message="message" />
            </div>

            <div v-if="isTyping" class="text-gray-500 text-sm italic">
              Someone is typing...
            </div>
          </template>

          <!-- Typing Indicator -->
          <div v-if="typingUsers.length > 0" class="text-gray-500 text-sm italic mt-2">
            {{ typingIndicatorText }}
          </div>
        </div>

        <!-- Message Input - fixed height -->
        <div class="shrink-0 border-t bg-white p-4">
          <div class="flex items-center space-x-4">
            <textarea
              ref="messageInput"
              v-model="newMessage"
              @keydown.enter.prevent="sendMessage"
              @input="handleTyping"
              placeholder="Type a message..."
              class="flex-1 resize-none border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-primary-500"
              rows="1"
            ></textarea>
            <button 
              @click="sendMessage"
              :disabled="!newMessage.trim()"
              class="btn btn-primary px-6"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddParticipantModal
    :show="showAddParticipantModal"
    :conversation-id="conversationId"
    @close="showAddParticipantModal = false"
    @participants-added="fetchConversationDetails(conversationId)"
  />
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ConversationList from './ConversationList.vue';
import api from '@/services/api';
import authService from '@/services/auth';
import AddParticipantModal from './AddParticipantModal.vue';
import { useConversationStore } from '@/store/conversationStore';
import { globalConnection, initializeConnection } from '@/services/signalrState';
import MessageItem from './MessageItem.vue';

export default {
  name: 'ChatContainer',
  
  components: {
    ConversationList,
    AddParticipantModal,
    MessageItem
  },
  
  setup() {
    const store = useStore();
    const conversationStore = useConversationStore();
    const route = useRoute();
    const conversationId = computed(() => route.params.conversationId);
    const messages = ref([]);
    const newMessage = ref('');
    const loading = ref(false);
    const isTyping = ref(false);
    const activeConversation = ref(null);
    const messagesContainer = ref(null);
    const typingTimeout = ref(null);
    const lastTypingSignal = ref(Date.now());
    const TYPING_DEBOUNCE = 2000; // 2 seconds between typing signals
    const TYPING_MIN_INTERVAL = 5000; // 5 seconds minimum between typing signals
    const currentUser = computed(() => authService.getCurrentUser());
    const showAddParticipantModal = ref(false);
    const typingUsers = ref([]);
    const messageInput = ref(null);

    // Computed property for typing indicator text
    const typingIndicatorText = computed(() => {
      if (typingUsers.value.length === 0) return '';
      if (typingUsers.value.length === 1) {
        return `${typingUsers.value[0].displayName} is typing...`;
      }
      if (typingUsers.value.length === 2) {
        return `${typingUsers.value[0].displayName} and ${typingUsers.value[1].displayName} are typing...`;
      }
      return 'Multiple people are typing...';
    });

    // Initialize SignalR connection
    onMounted(async () => {
      try {
        const connection = await initializeConnection('ChatContainer');
        
        // Add SignalR handlers
        connection.on('receiveMessage', (message) => {
          if (!message) {
            console.error('Received empty message from SignalR');
            return;
          }

          const messageConversationId = message.conversationId;    

          if (!messageConversationId || messageConversationId.toString() !== conversationId.value.toString()) {
            return;
          }

          const newMessage = {
            id: message.id,
            content: message.content,
            timestamp: message.timestampUtc,
            senderName: message.sender?.displayName || 'Unknown',
            isCurrentUser: message.sender?.id === currentUser.value?.id
          };

          messages.value.push(newMessage);
          scrollToBottom();
        });

        // Handle conversation added
        connection.on('ConversationAdded', (conversation) => {
          console.log('New conversation added:', conversation);
          
          const formattedConversation = {
            id: conversation.id,
            createdAtUtc: conversation.createdAtUtc,
            tags: conversation.tags,
            participants: conversation.participants,
            messages: conversation.messages?.map(msg => ({
              id: msg.id,
              content: msg.content,
              timestamp: msg.timestampUtc,
              senderName: msg.sender?.displayName,
              isCurrentUser: msg.sender?.id === currentUser.value?.id
            })) || []
          };
          
          conversationStore.handleNewConversation(formattedConversation);
        });

        // Handle participant added
        connection.on('ParticipantAdded', (updatedConversation) => {
          console.log('Participant added to conversation:', updatedConversation);
          if (conversationId.value === updatedConversation.id) {
            activeConversation.value = updatedConversation;
          }
          conversationStore.handleParticipantAdded(updatedConversation);
        });

        // Add typing indicator handler
        connection.on('typingIndicator', (userId, isTyping) => {
          if (userId === currentUser.value?.id) return;

          const foundUser = conversationStore.conversations
            .flatMap(convo => convo.participants || [])
            .find(participant => participant.id === userId);

          const userDisplayName = foundUser
            ? foundUser.firstName 
              ? `${foundUser.firstName} ${foundUser.lastName || ''}`.trim()
              : foundUser.displayName || 'Unknown'
            : 'Someone';

          const userIndex = typingUsers.value.findIndex(u => u.id === userId);

          if (isTyping && userIndex === -1) {
            typingUsers.value.push({ id: userId, displayName: userDisplayName });
          } else if (!isTyping && userIndex !== -1) {
            typingUsers.value.splice(userIndex, 1);
          }
        });

        if (conversationId.value) {
          await connection.invoke('JoinConversation', conversationId.value);
          await fetchConversationDetails(conversationId.value);
        }
      } catch (error) {
        console.error('Failed to initialize chat:', error);
      }
    });

    // Fetch conversation details
    const fetchConversationDetails = async (id) => {
      if (!id) return;
      
      loading.value = true;
      try {
        const response = await api.get(`conversations/${id}/details`);
        const data = response.data;
        
        activeConversation.value = {
          id: data.id,
          title: data.title,
          createdAtUtc: data.createdAtUtc,
          tags: data.tags,
          participants: data.participants || []
        };
        
        const mappedMessages = (data.messages || []).map(msg => ({
          id: msg.id,
          content: msg.content,
          timestamp: msg.timestampUtc,
          senderName: msg.sender?.displayName || 'Unknown',
          isCurrentUser: msg.sender?.id === currentUser.value?.id
        }));
        
        messages.value = mappedMessages;
        scrollToBottom();

        // Focus the message input after conversation details are loaded
        nextTick(() => {
          messageInput.value?.focus();
        });
      } catch (error) {
        console.error('Error fetching conversation details:', error);
      } finally {
        loading.value = false;
      }
    };

    // Send message
    const sendMessage = async () => {
      if (!newMessage.value.trim()) return;

      const messageContent = newMessage.value.trim();
      
      try {
        const connection = await initializeConnection();
        newMessage.value = '';
        await connection.invoke('SendMessage', conversationId.value, messageContent);
      } catch (error) {
        console.error('Error sending message:', error);
        newMessage.value = messageContent;
      }
    };

    // Handle typing indicator
    const handleTyping = async () => {
      if (typingTimeout.value) {
        clearTimeout(typingTimeout.value);
      }

      const now = Date.now();
      // Only send typing indicator if enough time has passed since last signal
      if (now - lastTypingSignal.value >= TYPING_MIN_INTERVAL) {
        try {
          if (!globalConnection.value || !conversationId.value) return;

          await globalConnection.value.invoke('TypingIndicator', conversationId.value.toString(), true);
          lastTypingSignal.value = now;

          typingTimeout.value = setTimeout(async () => {
            await globalConnection.value?.invoke('TypingIndicator', conversationId.value.toString(), false);
          }, TYPING_DEBOUNCE);
        } catch (error) {
          console.error('Error sending typing indicator:', error);
        }
      } else {
        // Just update the local timeout without sending a signal
        typingTimeout.value = setTimeout(async () => {
          await globalConnection.value?.invoke('TypingIndicator', conversationId.value.toString(), false);
        }, TYPING_DEBOUNCE);
      }
    };

    // Enhanced scrollToBottom function
    const scrollToBottom = () => {
      nextTick(() => {
        if (messagesContainer.value) {
          messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
        }
      });
    };

    // Call scrollToBottom when new messages arrive
    watch(() => messages.value, () => {
      scrollToBottom();
    }, { deep: true });

    // Call scrollToBottom when conversation changes
    watch(conversationId, () => {
      scrollToBottom();
    });

    // Call scrollToBottom after initial load
    onMounted(() => {
      scrollToBottom();
    });

    // Format timestamp
    const formatTimestamp = (timestamp) => {
      return new Date(timestamp).toLocaleTimeString();
    };

    // Handle scroll for infinite loading if needed
    const handleScroll = () => {
      // Implement infinite scroll logic here if needed
    };

    // Watch for conversation changes
    watch(conversationId, async (newId, oldId) => {
      try {
        if (newId && newId !== oldId) {
          const connection = await initializeConnection();
          await connection.invoke('JoinConversation', newId);
          await fetchConversationDetails(newId);
        }
      } catch (error) {
        console.error('Error handling conversation change:', error);
      }
    });

    const onParticipantsAdded = async (conversationId) => {
      await store.dispatch('conversations/fetchConversationDetails', conversationId);
    };

    // Clear typing users when changing conversations
    watch(conversationId, () => {
      typingUsers.value = [];
    });

    // Add this watcher after the other watch statements (around line 376)
    watch(typingUsers, () => {
      if (typingUsers.value.length > 0) {
        scrollToBottom();
      }
    }, { deep: true });

    return {
      conversationId,
      messages,
      newMessage,
      loading,
      isTyping,
      activeConversation,
      messagesContainer,
      sendMessage,
      handleTyping,
      formatTimestamp,
      handleScroll,
      showAddParticipantModal,
      onParticipantsAdded,
      fetchConversationDetails,
      typingUsers,
      typingIndicatorText,
      scrollToBottom,
      messageInput,
    };
  }
};
</script>

<style scoped>
.message-container {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.typing-indicator {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

/* Ensure smooth scrolling */
.overflow-y-auto {
  scroll-behavior: smooth;
}

/* Ensure fixed elements don't flex */
.shrink-0 {
  flex-shrink: 0;
}

/* Ensure the messages container takes remaining space */
.flex-1 {
  flex: 1 1 0%;
  min-height: 0; /* This is important for Firefox */
}
</style>

