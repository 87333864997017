<template>
  <div class="min-h-screen flex flex-col">
    <header class="h-16 bg-white shadow-sm fixed top-0 w-full z-50">
      <nav class="h-full flex items-center justify-between px-4">
        <router-link :to="{ name: 'home' }" class="text-2xl font-bold text-primary-600">ManagerCopilot</router-link>
        <div class="space-x-4">
          <template v-if="isLoggedIn">
            <router-link :to="{ name: 'tasks' }" class="btn btn-secondary">Tasks</router-link>
            <router-link :to="{ name: 'chat' }" class="btn btn-secondary">Chat</router-link>
            <button @click.prevent="logout" class="btn btn-primary">Logout</button>
          </template>
          <template v-else>
            <router-link :to="{ name: 'login' }" class="btn btn-secondary">Login</router-link>
            <router-link :to="{ name: 'register' }" class="btn btn-primary">Register</router-link>
          </template>
        </div>
      </nav>
    </header>
    <main class="flex-1 mt-16">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import authService from '@/services/auth';

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const isLoggedIn = ref(false);

    const updateAuthStatus = () => {
      isLoggedIn.value = authService.isAuthenticated();
    };

    const logout = () => {
      authService.logout();
      updateAuthStatus();
      router.push('/');
    };

    onMounted(() => {
      updateAuthStatus();
    });

    watch(() => router.currentRoute.value, () => {
      updateAuthStatus();
    });

    return {
      isLoggedIn,
      logout
    };
  }
};
</script>

<style>
@import './assets/styles/main.css';
</style>
