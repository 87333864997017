import { ref } from 'vue';
import { createHubConnection, startConnection } from './signalr';

export const globalConnection = ref(null);
export const isConnected = ref(false);
let connectionPromise = null;

export async function initializeConnection() {
  // If we already have a connected connection, return it
  if (globalConnection.value?.state === 'Connected') {
    return globalConnection.value;
  }

  // If we're already connecting, return the existing promise
  if (connectionPromise) {
    return connectionPromise;
  }

  // Create a new connection promise
  connectionPromise = (async () => {
    try {
      const connection = await createHubConnection();
      await startConnection(connection);
      globalConnection.value = connection;
      isConnected.value = true;
      
      connection.onclose(() => {
        globalConnection.value = null;
        isConnected.value = false;
        connectionPromise = null;
      });
      
      return connection;
    } catch (error) {
      console.error('Failed to initialize SignalR connection:', error);
      globalConnection.value = null;
      isConnected.value = false;
      connectionPromise = null;
      throw error;
    }
  })();

  return connectionPromise;
}

export function setConnection(connection) {
  globalConnection.value = connection;
  isConnected.value = true;
}

export function clearConnection() {
  if (globalConnection.value) {
    globalConnection.value.stop();
  }
  globalConnection.value = null;
  isConnected.value = false;
  connectionPromise = null;
} 