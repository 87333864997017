<template>
  <!-- Outer container: flex box + margin for spacing between messages -->
  <div
    class="w-full mb-2"
    :class="[message.isCurrentUser ? 'flex justify-end' : 'flex justify-start']"
  >
    <!-- If NOT current user, render their avatar on the left -->
    <div
      v-if="!message.isCurrentUser"
      class="w-8 h-8 shrink-0 rounded-full bg-gray-400 flex items-center justify-center mr-2"
    >
      <span class="text-white text-sm font-medium">{{ userInitials }}</span>
    </div>

    <!-- Text bubble -->
    <div
      :class="[
        'rounded-lg',
        'p-3',
        'max-w-lg',
        // Colors differ for the current user (outgoing) vs other user (incoming)
        message.isCurrentUser ? 'bg-primary-500 text-white' : 'bg-gray-100',
        // This margin ensures space between an avatar on either side.
        'mx-2'
      ]"
    >
      <div class="message-content break-words">{{ message.content }}</div>
      <div
        class="text-xs mt-1"
        :class="message.isCurrentUser ? 'text-primary-100' : 'text-gray-500'"
      >
        {{ formatTimestamp(message.timestamp) }}
      </div>
    </div>

    <!-- If current user, render our avatar on the right -->
    <div
      v-if="message.isCurrentUser"
      class="w-8 h-8 shrink-0 rounded-full bg-primary-500 flex items-center justify-center ml-2"
    >
      <span class="text-white text-sm font-medium">{{ userInitials }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageItem',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    userInitials() {
      const name = this.message.senderName || 'Unknown';
      return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    }
  },
  methods: {
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>

<style scoped>
.message-content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
</style>
