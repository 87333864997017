import { createStore } from 'vuex';
import chat from './chat';

const store = createStore({
  modules: {
    chat
  },
});

export default store;
