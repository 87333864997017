<template>
  <div v-if="show" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-6 w-96">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-semibold">
          {{ isNewConversation ? 'New Conversation' : 'Add Participants' }}
        </h3>
        <button @click="close" class="text-gray-500 hover:text-gray-700">
          <span class="sr-only">Close</span>
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="mb-4">
        <input
          ref="searchInput"
          v-model="searchPhrase"
          type="text"
          placeholder="Search users..."
          class="w-full border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-primary-500"
          @input="searchUsers"
        />
      </div>

      <div class="max-h-60 overflow-y-auto mb-4">
        <div
          v-for="user in searchResults"
          :key="user.id"
          class="flex items-center justify-between p-2 hover:bg-gray-100 rounded cursor-pointer"
          @click="selectUser(user)"
        >
          <div class="flex flex-col">
            <span class="font-medium">{{ user.firstName }} {{ user.lastName }}</span>
            <span class="text-sm text-gray-500">{{ user.email }}</span>
          </div>
          <button 
            class="text-primary-500 hover:text-primary-600 px-3 py-1 rounded-full border border-primary-500"
            @click.stop="selectUser(user)"
          >
            Add
          </button>
        </div>
      </div>

      <div v-if="selectedUsers.length > 0" class="mb-4">
        <h4 class="text-sm font-medium text-gray-700 mb-2">Selected users:</h4>
        <div class="flex flex-wrap gap-2">
          <span 
            v-for="user in selectedUsers" 
            :key="user.id"
            class="inline-flex items-center gap-1 px-2 py-1 bg-primary-100 text-primary-700 rounded-full text-sm"
          >
            {{ `${user.firstName} ${user.lastName}` }}
            <button 
              @click="removeUser(user)"
              class="text-primary-600 hover:text-primary-800"
            >
              ×
            </button>
          </span>
        </div>
      </div>

      <div class="flex justify-end gap-2">
        <button @click="close" class="btn btn-secondary">Cancel</button>
        <button 
          @click="handleAction"
          :disabled="isNewConversation ? false : selectedUsers.length === 0"
          class="btn btn-primary"
        >
          {{ isNewConversation ? 'Create Conversation' : 'Add Selected' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue';
import api from '@/services/api';

export default {
  name: 'AddParticipantModal',
  
  props: {
    show: Boolean,
    conversationId: String,
    isNewConversation: {
      type: Boolean,
      default: false
    }
  },
  
  emits: ['close', 'participants-added', 'participants-selected'],
  
  setup(props, { emit }) {
    const searchPhrase = ref('');
    const searchResults = ref([]);
    const selectedUsers = ref([]);
    const searchTimeout = ref(null);
    const searchInput = ref(null);

    const searchUsers = async () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
      }

      searchTimeout.value = setTimeout(async () => {
        if (searchPhrase.value.length < 2) {
          searchResults.value = [];
          return;
        }

        try {
          const response = await api.get(`participants/search?term=${searchPhrase.value}`);
          searchResults.value = response.data.participants || [];
        } catch (error) {
          console.error('Error searching users:', error);
          searchResults.value = [];
        }
      }, 300);
    };

    const selectUser = (user) => {
      if (!selectedUsers.value.find(u => u.id === user.id)) {
        selectedUsers.value.push(user);
      }
    };

    const addParticipants = async () => {
      try {
        await api.post('conversations/add_participants', {
          conversationId: props.conversationId,
          participantIds: selectedUsers.value.map(user => user.id)
        });
        
        emit('participants-added', props.conversationId);
        close();
      } catch (error) {
        console.error('Error adding participants:', error);
      }
    };

    const handleAction = async () => {
      if (props.isNewConversation) {
        emit('participants-selected', selectedUsers.value);
      } else {
        await addParticipants();
      }
      close();
    };

    const removeUser = (user) => {
      selectedUsers.value = selectedUsers.value.filter(u => u.id !== user.id);
    };

    const close = () => {
      searchPhrase.value = '';
      searchResults.value = [];
      selectedUsers.value = [];
      emit('close');
    };

    watch(() => props.show, (newValue) => {
      if (newValue) {
        nextTick(() => {
          searchInput.value?.focus();
        });
      }
    });

    return {
      searchPhrase,
      searchResults,
      selectedUsers,
      searchUsers,
      selectUser,
      handleAction,
      close,
      removeUser,
      searchInput,
    };
  }
};
</script> 