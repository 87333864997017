import conversationsService from '@/services/conversations';
import usersService from '@/services/users';

export default {
  async fetchConversations({ commit }) {
    try {
      const conversations = await conversationsService.getConversations();
      commit('SET_CONVERSATIONS', conversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      throw error;
    }
  },

  async loadConversation({ commit, dispatch }, conversationId) {
    try {
      const details = await conversationsService.getConversationDetails(conversationId);
      commit('SET_CURRENT_CONVERSATION', conversationId);
      commit('SET_MESSAGES', { conversationId, messages: details.messages });
      await dispatch('joinConversation', conversationId);
      return details;
    } catch (error) {
      console.error('Error loading conversation:', error);
      throw error;
    }
  },

  async sendMessage({ commit }, { conversationId, content }) {
    try {
      const message = await conversationsService.sendMessage({ conversationId, content });
      commit('ADD_MESSAGE', { conversationId, message });
      return message;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  },

  async handleTypingIndicator({ commit, state }, { userId, isTyping }) {
    try {
      const user = await usersService.getUserById(userId);
      commit('SET_TYPING_STATUS', { 
        conversationId: state.currentConversationId,
        user: { 
          id: user.id, 
          displayName: user.displayName, 
          isTyping 
        } 
      });
    } catch (error) {
      console.error('Error fetching user details for typing indicator:', error);
      // Fallback to generic user info
      commit('SET_TYPING_STATUS', { 
        conversationId: state.currentConversationId, 
        user: { 
          id: userId, 
          displayName: 'User', 
          isTyping 
        } 
      });
    }
  },

  handleReadReceipt({ commit }, { messageId, userId }) {
    commit('UPDATE_MESSAGE_STATUS', {
      messageId,
      status: {
        type: 'read',
        userId,
      },
    });
  },

  setConnectionStatus({ commit }, status) {
    commit('SET_CONNECTION_STATUS', status);
  },
};
