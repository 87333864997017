import { computed } from 'vue';
import { useRouter } from 'vue-router';
import authService from '@/services/auth';

export function useAuth() {
  const router = useRouter();

  const isAuthenticated = computed(() => authService.isAuthenticated());
  const currentUser = computed(() => authService.getCurrentUser());

  const login = async (email, password) => {
    await authService.login(email, password);
    router.push({ name: 'home' });
  };

  const logout = () => {
    authService.logout();
    router.push({ name: 'login' });
  };

  const register = async (userData) => {
    await authService.register(
      userData.email,
      userData.password,
      userData.firstName,
      userData.lastName,
      userData.companyName,
      userData.companySize
    );
    router.push({ name: 'login' });
  };

  return {
    isAuthenticated,
    currentUser,
    login,
    logout,
    register
  };
}
