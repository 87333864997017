import axios from 'axios';
import authService from './auth';

const API_URL = process.env.VUE_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  async (config) => {
    const token = authService.getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    
    // Only logout on actual 401 responses, not on other errors
    if (error.response?.status === 401) {
      console.log('Unauthorized request detected');
      // Check if this is a login/register endpoint before logging out
      const isAuthEndpoint = error.config.url.includes('/login') || 
                            error.config.url.includes('/register');
      if (!isAuthEndpoint) {
        authService.logout();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;
