import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import auth from './auth';

export async function createHubConnection() {
    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_API_URL}hubs/chat`, {
            accessTokenFactory: () => auth.getToken(),
            transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
            skipNegotiation: false,
            headers: {
                Authorization: `Bearer ${auth.getToken()}`
            }
        })
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect([0, 2000, 10000, 30000])
        .build();

    connection.onclose((error) => {
        if (error) {
            console.error('SignalR connection closed with error:', error);
        }
    });

    return connection;
}

export async function startConnection(connection) {
    try {
        await connection.start();
    } catch (err) {
        console.error('SignalR connection failed:', err);
        setTimeout(() => startConnection(connection), 5000);
    }
}
