import axios from 'axios';
import { reactive } from 'vue';
import store from '../store';

const API_URL = process.env.VUE_APP_API_URL;
const AUTH_URL = `${API_URL}users/`;

const state = reactive({
  user: JSON.parse(localStorage.getItem('user')) || null,
  isAuthenticated: false,
  tokens: {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    accessTokenExpiration: localStorage.getItem('tokenExpiration') || null,
    refreshTokenExpiration: localStorage.getItem('refreshTokenExpiration') || null
  }
});

// Initialize authentication state
state.isAuthenticated = !!state.tokens.accessToken;

const authService = {
  state,

  // Login
  async login(email, password) {
    try {
      const response = await axios.post(`${AUTH_URL}login`, { email, password });
      const userData = response.data;
      this.setAuthData(userData);
      return userData;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  // Logout
  logout() {
    this.clearAuthData();
    store.dispatch('auth/setToken', null);
  },

  // Set authentication data
  setAuthData(userData) {
    // Set tokens
    state.tokens = {
      accessToken: userData.userAccessTokens.accessToken,
      refreshToken: userData.userAccessTokens.refreshToken,
      accessTokenExpiration: userData.userAccessTokens.accessTokenExpiration,
      refreshTokenExpiration: userData.userAccessTokens.refreshTokenExpiration
    };

    // Set user data
    state.user = {
      id: userData.id,
      email: userData.email,
      displayName: userData.displayName,
      firstName: userData.firstName,
      lastName: userData.lastName
    };

    // Update state and storage
    state.isAuthenticated = true;
    this.updateLocalStorage();
    store.dispatch('auth/setToken', userData.userAccessTokens.accessToken);
  },

  // Clear authentication data
  clearAuthData() {
    state.tokens = {
      accessToken: null,
      refreshToken: null,
      accessTokenExpiration: null,
      refreshTokenExpiration: null
    };
    state.user = null;
    state.isAuthenticated = false;
    
    // Clear local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('refreshTokenExpiration');
    localStorage.removeItem('user');
  },

  // Update local storage
  updateLocalStorage() {
    localStorage.setItem('accessToken', state.tokens.accessToken);
    localStorage.setItem('refreshToken', state.tokens.refreshToken);
    localStorage.setItem('tokenExpiration', state.tokens.accessTokenExpiration);
    localStorage.setItem('refreshTokenExpiration', state.tokens.refreshTokenExpiration);
    localStorage.setItem('user', JSON.stringify(state.user));
  },

  // Register
  async register(email, password, firstName, lastName, companyName, companySize) {
    try {
      const response = await axios.post(`${AUTH_URL}register`, {
        email,
        password,
        firstName,
        lastName,
        companyName,
        companySize
      });
      return response.data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  // Get current user
  getCurrentUser() {
    return state.user;
  },

  // Check if user is authenticated
  isAuthenticated() {
    return state.isAuthenticated;
  },

  // Get token from localStorage
  getTokenFromStorage() {
    return localStorage.getItem('accessToken');
  },

  // Existing getToken method
  getToken() {
    return state.tokens.accessToken;
  }
};

export default authService;
