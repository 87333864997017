<template>
  <div class="bg-white rounded-lg shadow p-6">
    <h2 class="text-2xl font-bold text-gray-900 mb-6">Tasks</h2>
    <p v-if="error" class="text-red-600 mb-4">{{ error }}</p>
    <ul v-else-if="tasks.length" class="space-y-4">
      <li v-for="task in tasks" :key="task.id" 
          class="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
        <span class="text-gray-800">{{ task.name }}</span>
        <div class="space-x-2">
          <button @click="editTask(task)" 
                  class="btn btn-secondary">
            Edit
          </button>
          <button @click="deleteTask(task.id)" 
                  class="btn bg-red-500 text-white hover:bg-red-600">
            Delete
          </button>
        </div>
      </li>
    </ul>
    <p v-else class="text-gray-500 text-center py-8">No tasks found.</p>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      tasks: [],
      error: null
    };
  },
  mounted() {
    this.fetchTasks();
  },
  methods: {
    async fetchTasks() {
      try {
        this.error = null;
        const response = await api.get('/tasks/get');
        this.tasks = response.data;
      } catch (error) {
        console.error('Error fetching tasks:', error);
        this.error = `Error fetching tasks: ${error.message}`;
      }
    },
    editTask(task) {
      this.$emit('edit-task', task);
    },
    async deleteTask(id) {
      try {
        if (confirm('Are you sure you want to delete this task?')) {
          await api.delete(`/tasks/${id}/delete`);
          await this.fetchTasks();
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        this.error = `Failed to delete task: ${error.response?.data?.message || error.message}`;
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>

