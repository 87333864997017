export default {
    currentConversation(state) {
      if (!state.currentConversationId) return null;
      return state.conversations.find(convo => convo.id === state.currentConversationId);
    },
    conversationMessages: (state) => (conversationId) => {
      return state.messages[conversationId] || [];
    },
    typingUsers: (state) => (conversationId) => {
      return state.typingUsers[conversationId] || [];
    },
    isConnected(state) {
      return state.connectionStatus === 'connected';
    },
  };