import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:5075/';

const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with ID ${userId}:`, error);
    throw error;
  }
};

// Add more user-related methods as needed

export default {
  getUserById,
  // ... other user-related methods
};
