export default {
  SET_CONVERSATIONS(state, conversations) {
    state.conversations = conversations;
  },
  SET_CURRENT_CONVERSATION(state, conversationId) {
    state.currentConversationId = conversationId;
    if (!state.conversationDetails[conversationId]) {
      state.conversationDetails[conversationId] = {};
    }
  },
  SET_MESSAGES(state, { conversationId, messages }) {
    state.messages = {
      ...state.messages,
      [conversationId]: messages,
    };
  },
  ADD_MESSAGE(state, { conversationId, message }) {
    if (!state.messages[conversationId]) {
      state.messages[conversationId] = [];
    }
    state.messages[conversationId].push(message);
  },
  SET_TYPING_STATUS(state, { conversationId, user }) {
    if (!state.typingUsers[conversationId]) {
      state.typingUsers[conversationId] = [];
    }
    const existingUserIndex = state.typingUsers[conversationId].findIndex(u => u.id === user.id);
    if (user.isTyping) {
      if (existingUserIndex === -1) {
        state.typingUsers[conversationId].push(user);
      }
    } else {
      if (existingUserIndex !== -1) {
        state.typingUsers[conversationId].splice(existingUserIndex, 1);
      }
    }
  },
  UPDATE_MESSAGE_STATUS(state, { messageId, status }) {
    for (const conversationId in state.messages) {
      const message = state.messages[conversationId].find(msg => msg.id === messageId);
      if (message) {
        if (!message.receipts) {
          message.receipts = {};
        }
        if (!message.receipts[status.type]) {
          message.receipts[status.type] = [];
        }
        if (!message.receipts[status.type].includes(status.userId)) {
          message.receipts[status.type].push(status.userId);
        }
        break;
      }
    }
  },
  SET_CONNECTION_STATUS(state, status) {
    state.connectionStatus = status;
  },
};
