import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000/';

const getConversations = async () => {
  try {
    const response = await axios.get(`${API_URL}/conversations`);
    return response.data;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};

const getConversationDetails = async (conversationId) => {
  try {
    const response = await axios.get(`${API_URL}/conversations/${conversationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching conversation ${conversationId}:`, error);
    throw error;
  }
};

const sendMessage = async ({ conversationId, content }) => {
  try {
    const response = await axios.post(`${API_URL}/conversations/${conversationId}/messages`, { 
      content: content
    });
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

const addParticipants = async (conversationId, participantIds) => {
  try {
    const response = await axios.post(
      `${API_URL}conversations/add_participants`, 
      { 
        conversationId: conversationId,
        participantIds: participantIds
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding participants:', error);
    throw error;
  }
};

// Add more conversation-related methods as needed

export default {
  getConversations,
  getConversationDetails,
  sendMessage,
  addParticipants,
  // ... other methods
};
