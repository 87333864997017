<template>
  <div class="max-w-md mx-auto bg-white rounded-lg shadow-md p-8" v-if="!isAuthenticated">
    <h2 class="text-2xl font-bold text-gray-900 mb-6">Login</h2>
    <form @submit.prevent="handleLogin" class="space-y-6">
      <div>
        <label for="email" class="form-label">Email:</label>
        <input type="email" id="email" v-model="email" required class="form-input">
      </div>
      <div>
        <label for="password" class="form-label">Password:</label>
        <input type="password" id="password" v-model="password" required class="form-input">
      </div>
      <button type="submit" class="w-full btn btn-primary">Login</button>
      <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
      <p v-if="successMessage" class="mt-2 text-sm text-green-600">{{ successMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useAuth } from '@/composables/useAuth';

export default {
  setup() {
    const { login, isAuthenticated } = useAuth();
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const successMessage = ref(null);

    const handleLogin = async () => {
      error.value = null;
      successMessage.value = null;
      try {
        await login(email.value, password.value);
        successMessage.value = 'Login successful!';
      } catch (err) {
        error.value = err.response?.data?.message || 'An error occurred during login';
      }
    };

    return {
      email,
      password,
      error,
      successMessage,
      isAuthenticated,
      handleLogin
    };
  }
};
</script>

<style scoped>
.error {
  color: red;
}
.success {
  color: green;
}
</style>
