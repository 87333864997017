<template>
  <div class="conversation-list">
    <div class="mb-4">
      <button @click="showNewConversationModal = true" class="btn btn-primary w-full">
        New Conversation
      </button>
    </div>
    
    <div class="space-y-2">
      <div 
        v-for="conversation in conversations" 
        :key="conversation.id"
        @click="selectConversation(conversation)"
        class="p-4 bg-white rounded-lg shadow hover:bg-gray-50 cursor-pointer"
        :class="{ 'border-2 border-primary-500': selectedConversationId === conversation.id }"
      >
        <h3 class="font-medium">
          <span
            v-for="(participant, index) in conversation.participants"
            :key="participant.id"
            :class="{ 'text-blue-600': participant.type === 2 }"
          >
            {{ participant.type === 2 ? participant.firstName : `${participant.firstName} ${participant.lastName}` }}{{ index < conversation.participants.length - 1 ? ', ' : '' }}
          </span>
        </h3>
        <div class="flex gap-2 mt-1" v-if="conversation.tags && conversation.tags.length > 0">
          <span 
            v-for="tag in conversation.tags" 
            :key="tag.id"
            class="px-2 py-0.5 bg-gray-100 rounded-full text-xs text-gray-600"
          >
            {{ tag.tag }}
          </span>
        </div>
        <div class="text-xs text-gray-400 mt-1">
          {{ formatDate(conversation.createdAtUtc) }}
        </div>
      </div>
    </div>
  </div>

  <AddParticipantModal
    :show="showNewConversationModal"
    :is-new-conversation="true"
    @close="showNewConversationModal = false"
    @participants-selected="handleParticipantsSelected"
  />
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import api from '@/services/api';
import { useConversationStore } from '@/store/conversationStore';
import AddParticipantModal from './AddParticipantModal.vue';
import { initializeConnection } from '@/services/signalrState.js';

export default {
  name: 'ConversationList',
  
  components: {
    AddParticipantModal
  },
  
  setup() {
    const router = useRouter();
    const route = useRoute();
    const conversationStore = useConversationStore();
    const conversations = computed(() => conversationStore.conversations);
    const selectedConversationId = ref(null);
    const showNewConversationModal = ref(false);

    // Watch for route changes to update selected conversation
    watch(
      () => route.params.conversationId,
      (newConversationId) => {
        if (newConversationId) {
          selectedConversationId.value = newConversationId;
        }
      },
      { immediate: true } // This ensures it runs on initial load
    );

    const fetchConversations = async () => {
      try {
        const response = await api.get('conversations');
        
        conversationStore.conversations = response.data.conversations;
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    const handleParticipantsSelected = async (selectedParticipants) => {
      try {
        await api.post('conversations', {
          participants: selectedParticipants.map(p => p.id)
        });
        
        // Close modal immediately
        showNewConversationModal.value = false;
        
        // Navigation will happen when SignalR sends the conversationadded event
        // This prevents duplicate conversations from being added
      } catch (error) {
        console.error('Error creating conversation:', error);
      }
    };

    // Watch for SignalR events directly
    onMounted(async () => {
      try {      
        const connection = await initializeConnection('ConversationList');     
        
        // Add SignalR handlers
        connection.on('conversationadded', (conversation) => {
          if (!conversationStore.conversations.find(c => c.id === conversation.id)) {
            conversationStore.handleNewConversation(conversation);
            router.push({ 
              name: 'chat', 
              params: { conversationId: conversation.id }
            });
          }
        });

        connection.on('participantadded', (updatedConversation) => {
          conversationStore.handleParticipantAdded(updatedConversation);
        });

        await fetchConversations();
      } catch (error) {
        console.error('[ConversationList] Error setting up component:', error);
      }
    });

    const selectConversation = async (conversation) => {
      selectedConversationId.value = conversation.Id;
      try {
        // Fetch conversation details before navigation
        const response = await api.get(`conversations/${conversation.id}/details`);
        conversationStore.setCurrentConversation(response.data);
        conversationStore.setMessages(response.data.messages);
        
        router.push({ 
          name: 'chat', 
          params: { conversationId: conversation.id }
        });
      } catch (error) {
        console.error('Error fetching conversation details:', error);
        // You might want to add error handling here (e.g., show a notification)
      }
    };

    const formatDate = (dateStr) => {
      if (!dateStr) return 'No date';
      try {
        return new Date(dateStr).toLocaleString();
      } catch (error) {
        console.error('Error formatting date:', error);
        return 'Invalid Date';
      }
    };

 

    return {
      conversations,
      selectedConversationId,
      showNewConversationModal,
      handleParticipantsSelected,
      selectConversation,
      formatDate
    };
  }
};
</script>

<style scoped>
.conversation-list {
  width: 300px;
  padding: 1rem;
  border-right: 1px solid #e5e7eb;
  height: 100%;
  overflow-y: auto;
}
</style>
