import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useConversationStore = defineStore('conversation', () => {
  const currentConversation = ref(null);
  const messages = ref([]);
  const conversations = ref([]);
  const newConversationAdded = ref(false);

  const setCurrentConversation = (conversation) => {
    currentConversation.value = conversation;
  };

  const setMessages = (newMessages) => {
    messages.value = newMessages;
  };

  const handleParticipantAdded = (updatedConversation) => {
    if (currentConversation.value?.id === updatedConversation.id) {
      currentConversation.value = updatedConversation;
    }
    // Also update the conversation in the list
    const index = conversations.value.findIndex(c => c.id === updatedConversation.id);
    if (index !== -1) {
      conversations.value[index] = updatedConversation;
    }
  };

  const handleNewConversation = (conversation) => {
    if (!conversation?.id) return;

    if (!conversations.value.some(c => c.id === conversation.id)) {
      const newConversation = {
        id: conversation.id,
        participants: conversation.participants || [],
        tags: conversation.tags || [],
        createdAtUtc: conversation.createdAtUtc,
        messages: conversation.messages || []
      };
      
      conversations.value = [...conversations.value, newConversation];
      
      if (!currentConversation.value) {
        currentConversation.value = newConversation;
      }
    }
    
    newConversationAdded.value = true;
    setTimeout(() => {
      newConversationAdded.value = false;
    }, 100);
  };

  return {
    currentConversation,
    messages,
    conversations,
    newConversationAdded,
    setCurrentConversation,
    setMessages,
    handleParticipantAdded,
    handleNewConversation
  };
});
