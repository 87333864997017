<template>
  <div class="bg-white rounded-lg shadow p-6 mb-6">
    <h3 class="text-xl font-semibold text-gray-900 mb-4">
      {{ isEditing ? 'Edit Task' : 'Create New Task' }}
    </h3>
    <form @submit.prevent="submitTask" class="space-y-4">
      <div>
        <label for="title" class="form-label">Title:</label>
        <input type="text" id="title" v-model="task.name" required class="form-input">
      </div>
      <div>
        <label for="description" class="form-label">Description:</label>
        <textarea id="description" 
                  v-model="task.description" 
                  class="form-input min-h-[100px]"
                  rows="4"></textarea>
      </div>
      <div>
        <label for="dueDate" class="form-label">Due Date:</label>
        <input type="datetime-local" 
               id="dueDate" 
               v-model="localDueDate"
               class="form-input">
      </div>
      <div>
        <label for="status" class="form-label">Status:</label>
        <select id="status" v-model="task.status" class="form-input">
          <option value="0">Pending</option>
          <option value="1">Done</option>
        </select>
      </div>
      <div class="flex justify-end space-x-3 pt-4">
        <button type="button" @click="cancel" class="btn btn-secondary">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary">
          {{ isEditing ? 'Update' : 'Create' }} Task
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    editTask: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      task: {
        name: '',
        description: '',
        dueDateUtc: null,
        status: 0
      },
      localDueDate: '',
      isEditing: false
    };
  },
  watch: {
    editTask: {
      handler(newTask) {
        if (newTask) {
          this.task = { ...newTask };
          if (newTask.dueDateUtc) {
            this.localDueDate = new Date(newTask.dueDateUtc)
              .toISOString()
              .slice(0, 16);
          }
          this.isEditing = true;
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    async submitTask() {
      try {
        const taskData = {
          name: this.task.name,
          description: this.task.description,
          dueDateUtc: this.localDueDate ? new Date(this.localDueDate).toISOString() : null,
          status: parseInt(this.task.status)
        };

        if (this.isEditing) {
          taskData.taskItemId = this.task.id;
          await api.post('/tasks/update', taskData);
        } else {
          await api.post('/tasks/create', taskData);
        }
        this.$emit('task-submitted');
        this.resetForm();
      } catch (error) {
        console.error('Error submitting task:', error);
      }
    },
    resetForm() {
      this.task = { 
        name: '', 
        description: '', 
        dueDateUtc: null,
        status: 0 
      };
      this.localDueDate = '';
      this.isEditing = false;
    },
    cancel() {
      this.$emit('cancel');
      this.resetForm();
    }
  }
};
</script>
